:root {
  --bs-primary: #002060;
  --bs-primary-dark: #021a49;
  --bs-secondary: #006ec5;
  --bs-success: #01884e;
  --bs-primary-rgb: 0, 32, 96;
  --bs-secondary-rgb: 0, 110, 197;
  --bs-success-rgb: 1, 136, 78;
}
/* --bs-color-selectors */

.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-primary-dark);
  --bs-btn-hover-border-color: var(--bs-primary-dark);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
}

.btn-secondary {
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-bg: var(--bs-secondary-dark);
  --bs-btn-hover-border-color: var(--bs-secondary-dark);
  --bs-btn-focus-shadow-rgb: var(--bs-secondary-rgb);
}

::selection {
  background-color: #ffcc00;
  color: #000000;
}
