@import url('./root.css');

.bg-gray-200 {
  background-color: var(--bs-gray-200);
}

.bg-gray-500 {
  background-color: var(--bs-gray-500);
}
/*  */

.dropdown-item {
  --bs-dropdown-link-active-bg: var(--bs-primary);
}

/* TEAM CONTAINERS */

.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
  gap: 1rem;
}

/* 🤦‍♂️ */
.slider-card a {
  text-decoration: none;
  color: var(--bs-gray-800);
}
/*  */

.img-banner {
  height: 16rem;
  background-color: var(--bs-primary);
  background-repeat: no-repeat;
  background-size: cover;
}

.one-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

/*  */

.body {
  height: 100dvh;
  margin: 0;
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

section a {
  color: var(--bs-black);
  border-bottom: 2px var(--bs-secondary) dashed;
  text-decoration: none;
}

section a:hover {
  color: var(--bs-black);
  font-weight: 600;
}

@media (max-width: 1000px) {
  .side-bar {
    display: none;
  }
  .bd-placeholder-img {
    height: 100%;
    width: auto;
  }
}

@media (max-width: 768px) {
}
