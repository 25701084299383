/* FOOTER */
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: auto;

  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.footer-nav a {
  color: var(--bs-white);
  border-bottom: 2px var(--bs-secondary) dashed;
  text-decoration: none;
}

.footer-nav a:hover {
  color: var(--bs-white);
  font-weight: 600;
}

.footer-nav li {
  margin-bottom: 5px;
}
