/* POSTS SLIDER & POST GRID */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
}

.slider-container {
  display: flex;
  gap: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  width: 100%;
  height: fit-content;
  padding-bottom: 10px;
  margin-top: 15px;
}

.slider-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f5f5f5;
  visibility: hidden;
}

.slider-container::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 5px;
  visibility: hidden;
}

/* Show the scrollbar on hover */
.slider-container:hover::-webkit-scrollbar {
  visibility: visible;
}

.slider-container:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.slider-card a {
  text-decoration: none;
  color: var(--bs-gray-800);
}

.slider-card {
  flex: 0 0 22rem; /* set the width of each card */
  margin-right: 10px;
  margin: 0px;
  transition: transform 0.3s ease-in-out;
}

.slider-card:hover {
  /* transform: scale(1.02); */
  border: 1px dashed var(--bs-secondary);
}

.card-img-top {
  height: 8rem;
  background-color: #d1d0d0;
}

.post-card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.post-card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

/*  */

ol {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
