.bio {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
}

.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
  gap: 1rem;
}

.fill-parent {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member {
  cursor: zoom-in;
}

.team-member:hover {
  border: 1px dashed var(--bs-secondary);
}
