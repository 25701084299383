/* CAROUSEL */
.carousel-item {
  height: 36rem;
  background-color: var(--bs-primary);
  background-position: center;
  background-size: cover;
  position: relative;
}

.bd-placeholder-img {
  width: 100%;
  opacity: 0.15;
}

.carousel-item a {
  background-color: var(--bs-secondary);
  color: var(--bs-white);
  border: none;
}

.carousel-item a:hover {
  background-color: var(--bs-success);
}
