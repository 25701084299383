nav {
  background-color: var(--bs-white);
  /* border-top: 2px dashed var(--bs-secondary); */
  border-bottom: 2px dashed var(--bs-secondary);
}

.nav-item > a {
  color: var(--bs-black);
}

.navbar-nav .nav-link.active {
  color: var(--bs-success) !important;
  font-weight: 600;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-secondary) !important;
}

.affiliate_brand {
  display: flex;
  align-items: center;
  margin: 5px;
}

.affiliate_brand img {
  width: 80px;
  align-items: center;
}
